import axios from 'axios'

const SHARK_HUB_HOST = 'https://sharkhub.azurewebsites.net'
const SHARK_HUB_HOST_LOCAL = 'https://localhost:5001'

const DOMINIO_OMNI = axios.defaults.baseURL

const getPaginasFacebookConfiguradas = async () => {
    try {
        const { data } = await axios.get(`${SHARK_HUB_HOST}/api/integracao-facebook/paginas?dominio=${DOMINIO_OMNI}`)
        return data
    } catch (e) {
        console.log('erro getPaginasFacebookConfiguradas', e)
        throw (e)
    }
}

const getContaMercadolivreAutorizadaNoDominio = async () => {
    try {
        const { data } = await axios.get(`${SHARK_HUB_HOST}/integracao-mercadolivre/contas-autorizadas?dominio=${DOMINIO_OMNI}`)
        return data
    } catch (e) {
        console.log('erro getContaMercadolivreAutorizadaNoDominio', e)
        throw (e)
    }
}

const getContaShopeeAutorizadaNoDominio = async () => {
    try {
        const { data } = await axios.get(`${SHARK_HUB_HOST}/integracao-shopee/conta-autorizada?dominio=${DOMINIO_OMNI}`)
        return data
    } catch (e) {
        console.log('erro getContaShopeeAutorizadaNoDominio', e)
        throw (e)
    }
}

const getShopeeLinkDeAutorizacao = async () => {
    try {
        const { data } = await axios.get(`${SHARK_HUB_HOST}/integracao-shopee/link-autorizacao?dominio=${DOMINIO_OMNI}`)
        return data
    } catch (e) {
        console.log('erro getShopeeLinkDeAutorizacao', e)
        throw (e)
    }
}


// WHATSAPP BUSINESS
const getConsumoMensagensWhatsBusiness = async (mes, ano) => {
    try {
        const { data } = await axios.get(`${SHARK_HUB_HOST}/api/whatsbusiness/consumo-mensal?mes=${mes}&ano=${ano}&urlDominio=${DOMINIO_OMNI}`)
        return data
    } catch (e) {
        console.log('erro getConsumoMensagensWhatsBusiness', e)
        throw (e)
    }
}

// API SHARKHUB -> SKYHUB B2W
const getListaProdutosSkyhub = async (stringEncodePaginacao) => {
    try {
        const listaPaginacao = stringEncodePaginacao ? `?cursor=${stringEncodePaginacao}` : ''
        const { data } = await axios.get(`${SHARK_HUB_HOST}/integracao-skyhub/produtos${listaPaginacao}`)
        return data
    } catch (e) {
        console.log('erro getListaProdutosSkyhub', e)
        throw (e)
    }
}

const getListaCategoriasProdutosSkyhub = async () => {
    try {
        const { data } = await axios.get(`${SHARK_HUB_HOST}/integracao-skyhub/produto/categorias`)
        
        return data
    } catch (e) {
        console.log('erro getListaCategoriasProdutosSkyhub', e)
        throw (e)
    }
}
const getListaAtributosSkyHub = async () => {
    const { data } = await axios.get(`${SHARK_HUB_HOST}/integracao-skyhub/produto/atributos`)
    return data
}

const adicionarProdutoSkyHub = async (produto) => {
    try {
        const { data } = await axios.post(`${SHARK_HUB_HOST}/integracao-skyhub/produto`, produto)
        return data
    } catch (e) {
        console.log('erro adicionarProdutoSkyHub', e)
        throw (e)
    }
}

const editarProdutoSkyHub = async (produto) => {
    try {
        const { data } = await axios.put(`${SHARK_HUB_HOST}/integracao-skyhub/produto/${produto.product.skuInicial}`, produto)
        return data
    } catch (e) {
        console.log('erro editarProdutoSkyHub', e)
        throw (e)
    }
}

const alterarStatusProdutoSkyHub = async (skuProduto, status) => {
    try {
        const { data } = await axios.put(`${SHARK_HUB_HOST}/integracao-skyhub/produto/${skuProduto}/status/${status}`)
        return data
    } catch (e) {
        console.log('erro editarStatusProdutoSkyHub', e)
        throw (e)
    }
}

const removerProdutoSkyHub = async (sku) => {
    const { data } = await axios.delete(`${SHARK_HUB_HOST}/integracao-skyhub/produto/${sku}`)
    return data
}

const salvarVariavelProdutoSkyHub = async (variacaoProduto) => {
    const { data } = await axios.put(`${SHARK_HUB_HOST}/integracao-skyhub/produto/variacao/${variacaoProduto.variation.skuInicial}`, variacaoProduto)
    return data;
}

const uploadImagensSkyHub = async (formDataImagens) => {
    try {
        const { data } = await axios.post('/api/skyhub/imagens', formDataImagens, {
            headers: { 'Content-Type': 'multipart/form-data'}
        })
        return data
    } catch (e) {
        console.log('erro uploadImagensSkyHub', e)
        throw (e)
    }
}

const getListaPedidosSkyHub = async () => {
    const { data } = await axios.get(`${SHARK_HUB_HOST}/integracao-skyhub/pedidos`)
    return JSON.parse(data.result.replace('/[\]/g', ''));
}

export default {
    getPaginasFacebookConfiguradas,
    getContaMercadolivreAutorizadaNoDominio,
    getContaShopeeAutorizadaNoDominio,
    getShopeeLinkDeAutorizacao,
    getConsumoMensagensWhatsBusiness,

    // skyhub
    getListaProdutosSkyhub,
    getListaCategoriasProdutosSkyhub,
    getListaAtributosSkyHub,
    adicionarProdutoSkyHub,
    editarProdutoSkyHub,
    alterarStatusProdutoSkyHub,
    uploadImagensSkyHub,
    removerProdutoSkyHub,
    salvarVariavelProdutoSkyHub,
    getListaPedidosSkyHub
}